const baseUrl = `https://emtreport.visiontrek.in`
// const baseUrl = `http://localhost:6079`
const login = `${baseUrl}/login`;
export { login }

const homeglodate = `${baseUrl}/glo/date`
export { homeglodate }

const emtmonthdata = `${baseUrl}/month`
export { emtmonthdata }

const smslogs = `${baseUrl}/glo/sms-logs`
export { smslogs }

const smsCount = `${baseUrl}/glo/count/service`
export { smsCount }

const countData = `${baseUrl}/glo/count/servicedate`
export { countData }

const cashWinnerCount = `${baseUrl}/glo/countcashWinner`
export { cashWinnerCount }


const airtimeCountData = `${baseUrl}/glo/countairtimeData`
export { airtimeCountData }

const leftBalanceAirtime = `${baseUrl}/glo/left-airtimeblc`
export { leftBalanceAirtime }

const winnerlist = `${baseUrl}/glo/winnerList`
export { winnerlist }

const downloadDataAirtime = `${baseUrl}/glo/downloadCashData`
export { downloadDataAirtime }

const cashwinner = `${baseUrl}/glo/cashWinner-List`
export { cashwinner }

const countwinner = `${baseUrl}/glo/winnerList/count`
export { countwinner }

const emtlogs = `${baseUrl}/access-logs`
export { emtlogs }

const checkuserlogs = `${baseUrl}/insta-alert`
export { checkuserlogs }

const subscriptiondatedata = `${baseUrl}/date`
export { subscriptiondatedata }

const subscriptioninfo = `${baseUrl}/info`
export { subscriptioninfo }

const homegloinfo = `${baseUrl}/glo/info`
export { homegloinfo }

const accesslogsglo = `${baseUrl}/glo/access-logs`
export { accesslogsglo }

const checklogsglo = `${baseUrl}/glo/check-logs`
export { checklogsglo }

const checkCashStatus = `${baseUrl}/glo/change-cash-status`
export { checkCashStatus }

const UserLogsapi = `${baseUrl}/glo/user-logs`
export { UserLogsapi }
