import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaBars, FaTimes } from "react-icons/fa";
import Cookies from 'js-cookie';

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const logout = () => {
    console.log("handling logout")

    Cookies.remove('token');
    Cookies.remove('username');
    navigate('/')

  };

  return (
    <header className="bg-blue-500 text-white p-4 flex justify-between items-center">
      {/* Logo */}
      <div className="text-xl font-bold lg:text-2xl lg:ml-10 ml-4">Home</div>

      {/* Hamburger Icon for Mobile */}
      <div className=" ml-auto mr-8 mb-1 mt-4">
        <button onClick={toggleMenu}>
          {isOpen ? (
            <FaTimes className="h-8 w-8 text-white" />
          ) : (
            <FaBars className="h-8 w-8 text-white" />
          )}
        </button>
      </div>

      {/* Navigation */}
      {isOpen && (
        <nav className="lg:flex w-full md:w-[37%] lg:w-[17%] items-center space-x-6 lg:mt-0 lg:px-0 lg:gap-16 absolute right-0 top-20 h-auto z-10 bg-blue-500/90 px-2 py-4 ">
          <div className="flex flex-col items-center lg:gap-4 md:ml-[15%]">
            {/* <h1 className="md:p-5 z-10 text-white text-center text-xl md:text-3xl lg:text-xl font-sans p-2 lg:p-1 rounded-lg hover:bg-white hover:text-black">
              <Link to="/emt-service/check-logs" className="block lg:inline">Check User Logs</Link>
            </h1> */}
            <h1 className="md:p-5 z-10 text-white text-center text-xl md:text-3xl lg:text-xl font-sans p-2 lg:p-1 rounded-lg hover:bg-white hover:text-black">
              <Link to="/emt-service/user-logs" className="block lg:inline">User Logs</Link>
            </h1>
            <h1 className="md:p-5 z-10 text-white text-center text-xl md:text-3xl lg:text-xl font-sans p-2 lg:p-1 rounded-lg hover:bg-white hover:text-black">
              <Link to="/emt-service/subscription" className="block lg:inline">Get Subscription</Link>
            </h1>
            <h1 className="md:p-5 z-10 text-white text-center text-xl md:text-3xl lg:text-xl font-sans p-2 lg:p-1 rounded-lg hover:bg-white hover:text-black">
              <Link to="/emt-service/sms-logs" className="block lg:inline">SMS Logs</Link>
            </h1>
            <h1 className="md:p-5 z-10 text-white text-center text-xl md:text-3xl lg:text-xl font-sans p-2 lg:p-1 rounded-lg hover:bg-white hover:text-black">
              <Link to="/emt-service/airtime-winner" className="block lg:inline">AirTime Winner</Link>
            </h1>
            <h1 className="md:p-5 z-10 text-white text-center text-xl md:text-3xl lg:text-xl font-sans p-2 lg:p-1 rounded-lg hover:bg-white hover:text-black">
              <Link to="/emt-service/cash-winner" className="block lg:inline">Cash Winner</Link>
            </h1>
            <h1 className="md:p-5 z-10 text-white text-center text-xl md:text-3xl lg:text-xl font-sans p-2 lg:p-1 rounded-lg hover:bg-white hover:text-black">
              <Link to="/emt-service/access-logs" className="block lg:inline">Access Logs</Link>
            </h1>
            <button className="text-sm lg:p-2 p-2 mt-6 lg:ml-15 bg-white text-blue-800 rounded-lg text-right" onClick={logout}>
              Logout
            </button>
          </div>
        </nav>
      )}

    </header>
  );
}

export default Header;
