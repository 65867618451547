import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BiArrowBack } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import CheckToken from './CheckToken';
import { format } from 'date-fns';
import SelectSingleDate from './SelectSingleDate';
import Spinner from './Spinner';
import { airtimeCountData, countwinner, winnerlist,leftBalanceAirtime } from '../api/api';
import { ServiceNameDetails } from '../lib/ServicenameDetails';
import * as XLSX from 'xlsx';

function AirTime() {
  const [totalCount, setTotalCount] = useState(0);
  const [winnerLists, setWinnerLists] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [serviceCounter, setServiceCounter] = useState([]);
  const [dounloadDate, setDownloadData] = useState([]);
  const [leftAirtime, setLeftAirtime] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (!CheckToken('emt')) {
      navigate('/');
    }
  }, [navigate]);


  const [values, setValues] = useState({
    msisdn: '',
    DATE: null,
    service: ServiceNameDetails[0].name,
  });

  const handleChange = (e) => {
    if (e?.target) {
      setValues({
        ...values,
        [e.target.name]: e.target.value,
      });
    } else {
      setValues({
        ...values,
        DATE: e,
      });
    }
  };

  const fetchWinners = async (msisdn, DATE) => {
    console.log(DATE)
    setLoading(true);
    try {
      let formattedDate = '';
      if (DATE) {
        formattedDate = format(DATE, 'yyyy-MM-dd');
        // console.log('formattedDate: ', formattedDate);
      }
      const response = await axios.get(`${winnerlist}?msisdn=${msisdn}&serviceName=${values.service}&DATE=${formattedDate}`);
      if (response.data) {
        // console.log('response.data: ', response.data.message);
        setWinnerLists(response.data.message);
        setTotalCount(response.data.message.length);
        setShowAlert(response.data.message.length === 0);
      }
      if (response.data.message.length === 0) {
        setShowAlert(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchWinnerCount = async () => {
    // console.log(values.service);
    setLoading(true);
    try {
      const resp = await axios.get(`${countwinner}?serviceName=${values.service}`);
      if (resp.data) {
        const serviceCounts = resp.data.message.map(item => ({
          date: item.date,
          count: item.count[0].count,
          amount: item.amount ? item.amount : 0
        }));
        // console.log(serviceCounts)
        setServiceCounter(serviceCounts);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchleftAirtime = async () => {
    try {
      const response = await axios.get(`${leftBalanceAirtime}?serviceName=${values.service}`);
      // console.log('response: ', response.data.message.balance);
      if (response.data) {
        setLeftAirtime(response.data.message.balance);
      }
    } catch (error) {
      console.log(error);
    }
  };



  const handleSubmit = (e) => {
    e.preventDefault();
    fetchWinners(values.msisdn, values.DATE);
    fetchWinnerCount()
  };

  useEffect(() => {
    fetchWinnerCount();
  }, []);
  useEffect(() => {
    fetchWinnerCount();
    fetchleftAirtime();
  }, [values.service]);

  const formateDate = (dateTimeString) => {
    const date = new Date(dateTimeString);
    return format(date, 'yyyy-MM-dd HH:mm:ss');
  }

  const handleDownload = async (date) => {
    // console.log(date);
    try {
      const response = await axios.get(`${airtimeCountData}?serviceName=${values.service}&DATE=${date}`);
      // console.log('response: ', response.data.message.data);
      let downloadData = response.data.message.data;

      if (!Array.isArray(downloadData)) {
        downloadData = [downloadData];
      }

      setDownloadData(downloadData);

      const ws = XLSX.utils.json_to_sheet(downloadData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      XLSX.writeFile(wb, `airtime_logs_${date}.xlsx`);
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <div className='bg-black'>
      <h1 className='sticky top-0 z-10 text-white bg-gradient-to-r from-cyan-500 to-blue-500 flex items-center text-2xl md:text-3xl lg:text-4xl font-sans font-semibold p-6 md:p-3 lg:p-5'>
        <BiArrowBack size={30} className="mr-2" onClick={() => navigate('/emt-service')} />
        <span className="flex-1 text-center font-serif">EMT REPORTING PORTAL</span>
      </h1>

      <h1 className='text-white flex justify-center items-center uppercase mt-6 underline text-2xl'>Select Details</h1>

      <form onSubmit={handleSubmit} className='flex flex-col lg:flex-row justify-center items-center gap-4 mt-8'>
        <div className="lg:flex lg:flex-row lg:items-center flex flex-col">
          <label className='text-white text-xl'>Enter Number: </label>
          <input
            type='text'
            placeholder='Enter Number'
            name='msisdn'
            value={values.msisdn}
            onChange={handleChange}
            className='border lg:m-4 p-1 w-[180px] m-1 '
          />
        </div>

        <div className="lg:flex items-center">
          <div className="lg:flex lg:flex-row lg:items-center flex flex-col">
            <label className='text-white text-xl'>Select Service:  </label>
            <select name='service' value={values.service} onChange={handleChange} className='border p-1 m-1 w-[180px] lg:m-4'>
              {ServiceNameDetails.map((service, i) => (
                <option key={i} value={service.name}>
                  {service.name}
                </option>
              ))}
            </select>
          </div>

          <SelectSingleDate handleDateChange={handleChange} />
          <button type="submit"
            className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80
           font-medium rounded-lg text-sm px-5 py-2.5 text-center lg:mr-2 ml-12 lg:ml-8 mb-2 lg:mt-0 mt-12 md:mt-18">
            Submit
          </button>
        </div>
      </form>


      {
        !loading && 
        <div className="mt-6 lg:mt-4 lg-32 flex justify-center  items-center">
          <div className="w-[90%] lg:w-[600px] text-center m-4 lg:m-6 ">
          {leftAirtime > 0 && <h1 className='text-white'>Left AirTime Balance: {leftAirtime}</h1>}
        {/* <h1 className='text-white'>Left AirTime Balance</h1> */}
            <div className="relative overflow-x-auto shadow-lg rounded-lg">
              <table className="w-full text-sm text-left text-blue-100 dark:text-blue-100 mt-4">
                <thead className="text-md text-white uppercase bg-gradient-to-r from-cyan-400 to-blue-600">
                  <tr>
                    <th scope="col" className="px-4 sm:px-6 md:px-6 py-4">
                      service
                    </th>
                    <th scope="col" className="px-4 sm:px-6 md:px-6 py-4">
                      Date
                    </th>
                    <th scope="col" className="px-4 sm:px-6 md:px-6 py-4">
                      Count
                    </th>
                    <th scope="col" className="px-4 sm:px-6 md:px-6 py-4">
                      Balance
                    </th>
                    <th scope="col" className="px-4 sm:px-6 md:px-6 py-4">
                      Save Records
                    </th>

                  </tr>
                </thead>
                <tbody>
                  {serviceCounter?.map((item, i) => (
                    <tr key={i} className="bg-blue-100 text-black border-b border-blue-400">
                      <td className="px-4 sm:px-6 md:px-6 py-4">{values.service}</td>
                      <td className="px-4 sm:px-6 md:px-6 py-4">{item.date}</td>
                      <td className="px-4 sm:px-6 md:px-6 py-4">{item.count}</td>
                      <td className="px-4 sm:px-6 md:px-6 py-4">{item.amount}</td>
                      <td className="px-4 sm:px-6 md:px-6">
                        <button onClick={() => handleDownload(item.date)}
                          className="mt-2 lg:mt-4 mb-2 text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-600 
                dark:focus:ring-blue-600 shadow-lg 
                shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-3 
                py-2.5 text-center lg:mr-2 ml-6 lg:ml-2"
                        >
                          Download
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      }

      {loading && (
        <div className="text-center text-white h-screen">
          <Spinner />
        </div>
      )}

      {!loading && (
        <>  <div className='lg:px-14 lg:mt-8 md:px-8 md:mt-12 mt-8 px-1 pb-1 overflow-hidden'>
          {winnerLists.length > 0 ? (
            <table className='mx-auto mt-6 md:mt-4 lg:mt-1 lg:mb-12 w-full lg:w-full text-sm text-left text-blue-100 dark:text-blue-100 overflow-x-auto'>
              <thead className='text-md text-white uppercase bg-gradient-to-r from-cyan-400 to-blue-600 dark:text-white'>
                <tr>
                  <th scope='col' className='px-4 sm:px-6 md:px-6 py-4'>
                    MSISDN
                  </th>
                  <th scope='col' className='px-4 sm:px-6 md:px-6 py-4'>
                    service
                  </th>
                  <th scope='col' className='px-4 sm:px-6 md:px-6 py-4'>
                    Amount
                  </th>
                  <th scope='col' className='px-4 sm:px-6 md:px-6 py-4'>
                    Date
                  </th>
                </tr>
              </thead>
              <tbody>
                {winnerLists?.map((item, i) => (
                  <tr key={i} className='bg-blue-100 text-black border-b border-blue-400'>
                    <td className='px-4 sm:px-6 md:px-6 py-4'>{item.msisdn}</td>
                    <td className='px-4 sm:px-6 md:px-6 py-4'>{item.service}</td>
                    <td className='px-4 sm:px-6 md:px-6 py-4'>{item.amount}</td>
                    <td className='px-4 sm:px-6 md:px-6 py-4'>{formateDate(item.processDateTime)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            null
          )}
        </div>
          {
            showAlert && <p className="text-center text-white mt-8 text-2xl lg:text-5xl font-sans">
              Oops!<br />No data is available for the selected field.
            </p>
          }
        </>
      )}
    </div>
  );
}

export default AirTime;
