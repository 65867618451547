import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaBars, FaTimes } from "react-icons/fa";
import Cookies from 'js-cookie';

function GlowHeader() {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const logout = () => {
    console.log("handling logout")
  
    Cookies.remove('token');
    Cookies.remove('username');
    navigate('/')
  
  };

  return (
    <header className="bg-blue-500 text-white border-b-2 border-white p-4 flex justify-between items-center">
      {/* Logo */}
      <div className="text-xl font-serif lg:text-2xl lg:ml-10 ml-4 uppercase">Glo Emt Reporting portal</div>

      {/* Hamburger Icon for Mobile */}
      <div className="lg:hidden ml-auto mr-8 mb-1 mt-4">
        <button onClick={toggleMenu}>
          {isOpen ? (
            <FaTimes className="h-8 w-8 text-white" />
          ) : (
            <FaBars className="h-8 w-8 text-white" />
          )}
        </button>
      </div>

      {/* Navigation */}
      {isOpen && (
        <nav className="lg:flex items-center space-x-6 lg:mt-0 lg:px-0 lg:gap-16 absolute right-0 top-20 h-auto z-10 bg-blue-500/90 px-2 py-4 ">
          <div className="flex flex-col lg:flex-row items-center lg:gap-16">
            <h1 className="md:p-5 z-10 text-white text-center text-xl md:text-3xl lg:text-xl font-sans p-2 lg:p-1 rounded-lg hover:bg-white hover:text-black">
              <Link to="/glo/check-logs" className="block lg:inline">Check User Logs</Link>
            </h1>
            {/* <h1 className="md:p-5 z-10 text-white text-center text-xl md:text-3xl lg:text-xl font-sans p-2 lg:p-1 rounded-lg hover:bg-white hover:text-black">
              <Link to="/emt-service/subscription" className="block lg:inline">Get Subscription</Link>
            </h1> */}
            <h1 className="md:p-5 z-10 text-white text-center text-xl md:text-3xl lg:text-xl font-sans p-2 lg:p-1  hover:bg-white hover:text-black">
              <Link to="/glo/access-logs" className="block lg:inline">Access Logs</Link>
            </h1>
            <button className="text-sm lg:p-2 p-2 mt-6 lg:ml-20 bg-white text-blue-800 rounded-lg text-right" onClick={logout}>
              Logout
            </button>
          </div>
        </nav>
      )}
        <nav className={`lg:flex items-center space-x-6 lg:mt-0 lg:px-0 lg:gap-16 ${isOpen ? 'block absolute right-0 top-full bg-blue-400 px-2 py-4' : 'hidden lg:block'}`}>
        <div className="flex flex-col lg:flex-row items-center lg:gap-16">
          <h1 className="md:p-5 z-10 text-white text-center text-xl md:text-3xl lg:text-xl hover:text-lg font-sans p-2 lg:p-1 rounded-lg hover:bg-white hover:text-black">
            <Link to="/glo/check-logs" className="block lg:inline">Check User Logs</Link>
          </h1>
          {/* <h1 className="md:p-5 z-10 text-white text-center text-xl md:text-3xl lg:text-xl font-sans p-2 lg:p-1 rounded-lg hover:bg-white hover:text-black">
            <Link to="/emt-service/subscription" className="block lg:inline">Get Subscription</Link>
          </h1> */}
          <h1 className="md:p-5 z-10 text-white text-center text-xl md:text-3xl lg:text-xl hover:text-lg font-sans p-2 lg:p-1 rounded-lg hover:bg-white hover:text-black">
            <Link to="/glo/access-logs" className="block lg:inline">Access Logs</Link>
          </h1>
          <button className="text-sm lg:p-2 p-1 lg:ml-20 bg-white hover:bg-blue-100 text-blue-800 rounded-lg text-right font-bold" onClick={logout}>
            Logout
          </button>
        </div>
      </nav>
    </header>
  );
}

export default GlowHeader;
