// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { BiArrowBack } from 'react-icons/bi';
// import { useNavigate } from 'react-router-dom';
// import CheckToken from '../components/CheckToken';
// import { UserLogsapi } from '../api/api';
// import { ServiceNameDetails } from '../lib/ServicenameDetails';
// import Spinner from './Spinner';

// function UserLogs() {

//     const navigate = useNavigate();

//     useEffect(() => {
//         if (!CheckToken('emt')) {
//             navigate('/');
//         }
//     }, [navigate]);

//     const [dataAvailable, setDataAvailable] = useState(true);
//     const [Operation, setOperation] = useState('');
//     const [loading, setLoading] = useState(false);
//     const [values, setValues] = useState({
//         msisdn: '',
//         operation: '',
//         service: '',
//     });
//     const [tabledata, setTableData] = useState([]);
//     console.log("tabledata:", tabledata);

//     const handleChange = (e) => {
//         console.log('Event:', e.target.value);
//         setValues({
//             ...values,
//             [e.target.name]: e.target.value,
//         });
//     };

//     const handleSubmit = (e) => {
//         e.preventDefault();
//         if (values.service === 'none') {
//             values.service = ''
//         }
//         setOperation(values.operation)
//         console.log('Form submitted:', values);
//         setLoading(true)
//         axios.get(`${UserLogsapi}?msisdn=${values.msisdn}&operation=${values.operation}&service=${values.service}`)
//             .then((res) => {
//                 console.log('Response from the backend:', res.data);

//                 if (Array.isArray(res.data.message)) {
//                     setTableData(res.data.message);
//                     setDataAvailable(res.data.message.length > 0);
//                 } else {
//                     setTableData([]);
//                     setDataAvailable(false);
//                 }
//                 setLoading(false)
//             })
//             .catch((error) => {
//                 console.log(error);
//                 setTableData([]);
//                 setDataAvailable(false);
//                 setLoading(false)
//             });

//     };

//     const dateSplit = (date) => {
//         if (!date) return '';
//         const splitDate = date.split('T');
//         return splitDate[0];
//     }

//     return (
//         <div className='bg-black'>
//             <h1 className='sticky top-0 z-10 text-white bg-gradient-to-r from-cyan-500 to-blue-500 flex items-center text-2xl md:text-3xl lg:text-4xl font-sans font-semibold p-6 md:p-3 lg:p-5'>
//                 <BiArrowBack size={30} className="mr-2" onClick={() => navigate('/emt-service')} />
//                 <span className="flex-1 text-center font-serif">EMT REPORTING PORTAL</span>
//             </h1>

//             <h1 className='text-white flex justify-center items-center uppercase mt-6 underline text-2xl'>Select Details</h1>

//             <form onSubmit={handleSubmit} className='flex flex-col lg:flex-row justify-center items-center gap-4 mt-8'>
//                 <div className="lg:flex lg:flex-row lg:items-center flex flex-col">
//                     <label className='text-white text-xl'>Enter Number: </label>
//                     <input
//                         type='text'
//                         placeholder='Enter Number'
//                         name='msisdn'
//                         value={values.msisdn}
//                         onChange={handleChange}
//                         className='border lg:m-4 p-1 w-[180px] m-1 '
//                     />
//                 </div>

//                 <div className="lg:flex lg:flex-row lg:items-center flex flex-col">
//                     <label className='text-white text-xl'>Select Operation:  </label>
//                     <select name='operation' value={values.operation} onChange={handleChange} className='border p-1 m-1 w-[180px] lg:m-4'>
//                         <option value='none'>Select an option</option>
//                         <option value='Activation'>Activation</option>
//                         <option value='Deactivation'>Deactivation</option>
//                         <option value='Billing'>Billing Records</option>
//                         <option value='SMS'>SMS Logs</option>
//                     </select>
//                 </div>

//                 <div className="lg:flex items-center">
//                     <div className="lg:flex lg:flex-row lg:items-center flex flex-col">
//                         <label className='text-white text-xl'>Select Service:  </label>
//                         <select name='service' value={values.service} onChange={handleChange} className='border p-1 m-1 w-[180px] lg:m-4'>
//                             <option value='none'>Select an option</option>

//                             {ServiceNameDetails.map((service, i) => (

//                                 <option key={i} value={service.name}>
//                                     {service.name}
//                                 </option>
//                             ))}
//                         </select>
//                     </div>

//                     <button type="submit"
//                         className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80
//            font-medium rounded-lg text-sm px-5 py-2.5 text-center lg:mr-2 ml-12 lg:ml-8 mb-2 lg:mt-0 mt-12 md:mt-18">
//                         Submit
//                     </button>
//                 </div>
//             </form>

//             {loading && (
//                 <div className="text-center text-white h-screen">
//                     <Spinner />
//                 </div>
//             )}

//             {!loading && Array.isArray(tabledata) && tabledata.length > 0 ? (
//                 <div className="p-8 overflow-x-auto text-4xl shadow-md sm:rounded-lg lg:mt-6">
//                     <h1 className='text-white flex justify-center items-center uppercase underline mt-6 text-2xl'>{Operation} - Details</h1>

//                     <table className="w-full text-sm text-left text-black dark:text-blue-100 mt-4 rounded-lg">
//                         <thead className="text-md text-white uppercase bg-blue-600 border-b border-blue-400 dark:text-white">
//                             <tr>
//                                 <th scope="col" className="px-6 py-3 bg-blue-500">
//                                     msisdn
//                                 </th>
//                                 <th scope="col" className="px-6 py-3 bg-blue-500">
//                                     type
//                                 </th>
//                                 <th scope="col" className="px-6 py-3 bg-blue-500">
//                                     packtype
//                                 </th>

//                                 <th scope="col" className="px-6 py-3 bg-blue-500">
//                                     {values.operation === 'Deactivation'
//                                         ? 'sub_date_time'
//                                         : values.operation === 'Activation'
//                                             ? 'sub_date_time'
//                                             : 'billed_date'}
//                                 </th>
//                                 <th scope="col" className="px-6 py-3 bg-blue-500">
//                                     {values.operation === 'Deactivation'
//                                         ? 'last_billed_date'
//                                         : values.operation === 'Activation'
//                                             ? 'last_billed_date'
//                                             : 'next_billed_date'}
//                                 </th>
//                                 <th scope="col" className="px-6 py-3 bg-blue-500">
//                                     {values.operation === 'Deactivation'
//                                         ? 'unsub_date_time'
//                                         : values.operation === 'Activation'
//                                             ? 'next billed Date'
//                                             : 'serviceName'}
//                                 </th>

//                                 <th scope="col" className="px-6 py-3 bg-blue-500">
//                                     {values.operation === 'Deactivation'
//                                         ? 'service'
//                                         : values.operation === 'Activation'
//                                             ? 'service'
//                                             : ''}
//                                 </th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {tabledata.map((item, i) => (
//                                 <tr key={i} className="bg-white text-black border-b border-blue-400">
//                                     <td className="px-4 sm:px-6 md:px-6 py-4 ">{item.msisdn}</td>
//                                     <td className="px-4 sm:px-6 md:px-6 py-4">{item.type_event}</td>
//                                     <td className="px-4 sm:px-6 md:px-6 py-4">{item.packType}</td>
//                                     <td className="px-4 sm:px-6 md:px-6 py-4">
//                                         {values.operation === 'Deactivation'
//                                             ? dateSplit(item.sub_date_time)
//                                             : values.operation === 'Activation'
//                                                 ? dateSplit(item.sub_date_time)
//                                                 : dateSplit(item.billed_date)}
//                                     </td>
//                                     <td className="px-4 sm:px-6 md:px-6 py-4">
//                                         {values.operation === 'Deactivation'
//                                             ? dateSplit(item.last_billed_date)
//                                             : values.operation === 'Activation'
//                                                 ? dateSplit(item.last_billed_date)
//                                                 : dateSplit(item.next_billed_date)}
//                                     </td>
//                                     <td className="px-4 sm:px-6 md:px-6 py-4">
//                                         {values.operation === 'Deactivation'
//                                             ? dateSplit(item.unsub_date_time)
//                                             : values.operation === 'Activation'
//                                                 ? dateSplit(item.next_billed_date)
//                                                 : item.serviceName}
//                                     </td>
//                                     <td className="px-4 sm:px-6 md:px-6 py-4">
//                                         {values.operation === 'Deactivation'
//                                             ? item.serviceName
//                                             : values.operation === 'Activation'
//                                                 ? item.serviceName
//                                                 : ""}
//                                     </td>
//                                 </tr>
//                             ))}
//                         </tbody>
//                     </table>
//                 </div>
//             ) : null}

//             {!dataAvailable && (
//                 <p className="text-center text-white mt-8 text-2xl lg:text-5xl font-sans">
//                     Oops!<br />No data is available for the selected criteria.
//                 </p>
//             )}
//         </div>
//     );
// }

// export default UserLogs;



import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BiArrowBack } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import CheckToken from '../components/CheckToken';
import { UserLogsapi } from '../api/api';
import { ServiceNameDetails } from '../lib/ServicenameDetails';
import Spinner from './Spinner';

function UserLogs() {
    const navigate = useNavigate();

    useEffect(() => {
        if (!CheckToken('emt')) {
            navigate('/');
        }
    }, [navigate]);

    const [dataAvailable, setDataAvailable] = useState(true);
    const [Operation, setOperation] = useState('');
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState({
        msisdn: '',
        operation: '',
        service: '',
    });
    const [tabledata, setTableData] = useState([]);
    console.log("tabledata:", tabledata);

    const handleChange = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        });
    };
    useEffect(() => {
        if (values.operation) {
            handleSubmit({ preventDefault: () => { } });
        }
    }, [values.operation]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (values.service === 'none') {
            values.service = '';
        }
        setOperation(values.operation);
        setLoading(true);
        axios.get(`${UserLogsapi}?msisdn=${values.msisdn}&operation=${values.operation}&service=${values.service}`)
            .then((res) => {
                console.log('Response from the backend:', res.data);

                if (Array.isArray(res.data.message)) {
                    setTableData(res.data.message);
                    setDataAvailable(res.data.message.length > 0);
                } else {
                    setTableData([]);
                    setDataAvailable(false);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setTableData([]);
                setDataAvailable(false);
                setLoading(false);
            });
    };

    const dateSplit = (date) => {
        if (!date) return '';
        const splitDate = date.split('T');
        return splitDate[0];
    };

    return (
        <div className='bg-black'>
            <h1 className='sticky top-0 z-10 text-white bg-gradient-to-r from-cyan-500 to-blue-500 flex items-center text-2xl md:text-3xl lg:text-4xl font-sans font-semibold p-6 md:p-3 lg:p-5'>
                <BiArrowBack size={30} className="mr-2" onClick={() => navigate('/emt-service')} />
                <span className="flex-1 text-center font-serif">EMT REPORTING PORTAL</span>
            </h1>

            <h1 className='text-white flex justify-center items-center uppercase mt-6 underline text-2xl'>Select Details</h1>

            <form onSubmit={handleSubmit} className='flex flex-col lg:flex-row justify-center items-center gap-4 mt-8'>
                <div className="lg:flex lg:flex-row lg:items-center flex flex-col">
                    <label className='text-white text-xl'>Enter Number: </label>
                    <input
                        type='text'
                        placeholder='Enter Number'
                        name='msisdn'
                        value={values.msisdn}
                        onChange={handleChange}
                        className='border lg:m-4 p-1 w-[180px] m-1 '
                    />
                </div>

                <div className="lg:flex lg:flex-row lg:items-center flex flex-col">
                    <label className='text-white text-xl'>Select Operation:  </label>
                    <select name='operation' value={values.operation} onChange={handleChange} className='border p-1 m-1 w-[180px] lg:m-4'>
                        <option value='none'>Select an option</option>
                        <option value='Activation'>Activation</option>
                        <option value='Deactivation'>Deactivation</option>
                        <option value='Billing'>Billing Records</option>
                        <option value='SMS'>SMS Logs</option>
                    </select>
                </div>

                <div className="lg:flex items-center">
                    <div className="lg:flex lg:flex-row lg:items-center flex flex-col">
                        <label className='text-white text-xl'>Select Service:  </label>
                        <select name='service' value={values.service} onChange={handleChange} className='border p-1 m-1 w-[180px] lg:m-4'>
                            <option value='none'>Select an option</option>
                            {ServiceNameDetails.map((service, i) => (
                                <option key={i} value={service.name}>
                                    {service.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <button type="submit"
                        className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80
                        font-medium rounded-lg text-sm px-5 py-2.5 text-center lg:mr-2 ml-12 lg:ml-8 mb-2 lg:mt-0 mt-12 md:mt-18">
                        Submit
                    </button>
                </div>
            </form>

            {loading && (
                <div className="text-center text-white h-screen">
                    <Spinner />
                </div>
            )}

            {!loading && Array.isArray(tabledata) && tabledata.length > 0 ? (
                values.operation === 'SMS' ? (
                    <div className="p-8 overflow-x-auto text-4xl shadow-md sm:rounded-lg lg:mt-6">
                        <h1 className='text-white flex justify-center items-center uppercase underline mt-6 text-2xl'>SMS Logs</h1>
                        <table className="w-full text-sm text-left text-black dark:text-blue-100 mt-4 rounded-lg">
                            <thead className="text-md text-white uppercase bg-blue-600 border-b border-blue-400 dark:text-white">
                                <tr>
                                    <th scope="col" className="px-6 py-3 bg-blue-500">Date</th>
                                    <th scope="col" className="px-6 py-3 bg-blue-500">MSISDN</th>
                                    <th scope="col" className="px-6 py-3 bg-blue-500">Message</th>

                                </tr>
                            </thead>
                            <tbody>
                                {tabledata.map((item, i) => (
                                    <tr key={i} className="bg-white text-black border-b border-blue-400">
                                        <td className="px-4 sm:px-6 md:px-6 py-4">{dateSplit(item.processDateTime)}</td>
                                        <td className="px-4 sm:px-6 md:px-6 py-4">{item.msisdn}</td>
                                        <td className="px-4 sm:px-6 md:px-6 py-4">{item.message}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    // Existing table structure for other operations
                    <div className="p-8 overflow-x-auto text-4xl shadow-md sm:rounded-lg lg:mt-6">
                        <h1 className='text-white flex justify-center items-center uppercase underline mt-6 text-2xl'>{Operation} - Details</h1>
                        <table className="w-full text-sm text-left text-black dark:text-blue-100 mt-4 rounded-lg">
                            <thead className="text-md text-white uppercase bg-blue-600 border-b border-blue-400 dark:text-white">
                                <tr>
                                    <th scope="col" className="px-6 py-3 bg-blue-500">msisdn</th>
                                    <th scope="col" className="px-6 py-3 bg-blue-500">type</th>
                                    <th scope="col" className="px-6 py-3 bg-blue-500">packtype</th>
                                    <th scope="col" className="px-6 py-3 bg-blue-500">
                                        {values.operation === 'Deactivation' ? 'sub_date_time' : values.operation === 'Activation' ? 'sub_date_time' : 'billed_date'}
                                    </th>
                                    <th scope="col" className="px-6 py-3 bg-blue-500">
                                        {values.operation === 'Deactivation' ? 'last_billed_date' : values.operation === 'Activation' ? 'last_billed_date' : 'next_billed_date'}
                                    </th>
                                    <th scope="col" className="px-6 py-3 bg-blue-500">
                                        {values.operation === 'Deactivation' ? 'unsub_date_time' : values.operation === 'Activation' ? 'next billed Date' : 'serviceName'}
                                    </th>
                                    <th scope="col" className="px-6 py-3 bg-blue-500">
                                        {values.operation === 'Deactivation' || values.operation === 'Activation' ? 'service' : ''}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {tabledata.map((item, i) => (
                                    <tr key={i} className="bg-white text-black border-b border-blue-400">
                                        <td className="px-4 sm:px-6 md:px-6 py-4 ">{item.msisdn}</td>
                                        <td className="px-4 sm:px-6 md:px-6 py-4">{item.type_event}</td>
                                        <td className="px-4 sm:px-6 md:px-6 py-4">{item.packType}</td>
                                        <td className="px-4 sm:px-6 md:px-6 py-4">
                                            {values.operation === 'Deactivation' || values.operation === 'Activation'
                                                ? dateSplit(item.sub_date_time)
                                                : dateSplit(item.billed_date)}
                                        </td>
                                        <td className="px-4 sm:px-6 md:px-6 py-4">
                                            {values.operation === 'Deactivation' || values.operation === 'Activation'
                                                ? dateSplit(item.last_billed_date)
                                                : dateSplit(item.next_billed_date)}
                                        </td>
                                        <td className="px-4 sm:px-6 md:px-6 py-4">
                                            {values.operation === 'Deactivation' ? dateSplit(item.unsub_date_time) : values.operation === 'Activation' ? dateSplit(item.next_billed_date) : item.serviceName}
                                        </td>
                                        <td className="px-4 sm:px-6 md:px-6 py-4">{item.serviceName}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )
            ) : null}

            {!dataAvailable && (
                <p className="text-center text-white mt-8 text-2xl lg:text-5xl font-sans">
                    Oops!<br />No data is available for the selected criteria.
                </p>
            )}
        </div>
    );
}

export default UserLogs;
