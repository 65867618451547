export const ServiceNameDetails = [
    {
        name: 'Football Quiz',
        psisdn: ["174", "175"]
    },
    {
        name: 'Instant Game',
        psisdn: ["176", "177"]
    },
    {
        name: 'Goal Alert',
        psisdn: ["178"]
    },
    {
        name: 'Video Central',
        psisdn: ["188"]
    },
    {
        name: 'Gam Box',
        psisdn: ["198"]
    },
    {
        name: 'NpflFootball Quiz',
        psisdn: ["199", "200"]
    },
    {
        name: 'Instant Game Glo',
        psisdn: ["204"]
    }
];
