
// import Cookies from 'js-cookie';


// const CheckToken = () => {
//   const token = Cookies.get('token');
  
//   return !!token; 
// };

// export default CheckToken

import Cookies from 'js-cookie';

const CheckToken = (allowedUser) => {
  const token = Cookies.get('token');
  const username = Cookies.get('username');
  return !!token && (allowedUser === undefined || username === allowedUser);

};

export default CheckToken;



